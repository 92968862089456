import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css';

if (process.env.VUE_APP_ENVIRONMENT == "ENV") {
  console.log("当前环境:开发环境")
} else if (process.env.VUE_APP_ENVIRONMENT == "BUILD"){
  console.log("当前环境:生产环境")
}


createApp(App).use(store).use(router).mount('#app')
