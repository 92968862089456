<template>
  <router-view/>
</template>

<script>
import { mapState, mapMutations } from 'vuex'//引入mapMutations
import service from '@/request/index'; // 请求
import { showLoadingToast, closeToast, showToast } from 'vant';
import { renovateData } from '@/assets/js/public.js'; // 请求


export default {
  name: 'App',
  components: {
  },
  data: () => ({
  }),
  // 创建
  created() {
    
  },
  // 计算属性
  computed: {
    ...mapState({
      brandsData: (state) => state.brandsData,
      panelIndex: (state) => state.panelIndex,
    }),
  },
  watch: {
    
  },
  async mounted() {
    await this.getBrandsData(); // 获取品牌数据
    await renovateData(this.brandsData[this.panelIndex].key); // 初始化面板数据
  },
  methods: {
    ...mapMutations(['updateBrandsData', 'updateMediaCategory', 'updatePlatforms','updateSpreadPlatform']),

    // 获取品牌数据
    async getBrandsData() {
      await service.get('brands').then(res => {
        if (res.code == 1) {
          let Data = res.data;
          let brandsData = [];
          for (let key in Data.brands) {
            brandsData.push({
              name: Data.brands[key],
              key
            });
          }
          this.updateBrandsData(brandsData); // 更细品牌
          this.updateMediaCategory(Data.media_category); // 更新媒体信息
          this.updatePlatforms(Data.platforms); // 更新平台
          this.updateSpreadPlatform(Data.spread_platform); // 更新扩张平台
        } else {
          showToast(res.msg)
        }
      })
    },

  }
}
</script>


<style lang="scss">
* {
    margin: 0;
    padding: 0;
}
@font-face {
  font-family: "pingfang";
  src: url("./assets/Fonts/pinfang.ttf");
  font-stretch: "normal";
  font-weight: 400;
}

html,
body {
    width: 100%;
    background: #fff;
    font-family: "pingfang", "Microsoft YaHei", "微软雅黑", "PingFangSC-Regular", "PingFangSC-Light", "sans-serif" !important;
    -webkit-overflow-scrolling: touch;
}
html::-webkit-scrollbar {
    width: 3px;
}
html::-webkit-scrollbar-thumb {
    border-radius: 99999px;
    background-color: #b3b3b3;
}
img {
    display: block;
    font-size: 0;
}
li {
    list-style: none;
}
a {
    text-decoration: none;
    cursor: default;
}
span {
    display: block;
}
input,
textarea {
    font-family: "changan", "Microsoft YaHei", "微软雅黑", "PingFangSC-Regular",
        "PingFangSC-Light", "sans-serif";
    outline: none;
    border: none;
    display: block;
}
iframe{
    display: block;
}

#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: "pingfang", "Microsoft YaHei", "微软雅黑", "PingFangSC-Regular", "PingFangSC-Light", "sans-serif" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>
