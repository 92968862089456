import { createStore } from 'vuex'

export default createStore({
  state: {
    timerObj: Object, // 定时器
    autoplayDelay: 50000, // 自动播放面板间隔
    menuSwiperObj: Object, // 对象
    animationDuration: 4000, // 图表动画时间
    panelIndex: 0, // 全局面板索引
    // menuTabIndex: 0, // 菜单索引
    mediumMenuIndex: 0, // 媒体菜单索引
    brandsData: [], // 品牌数据
    mediaCategory: {}, // 媒体信息
    platforms: {}, // 平台
    spreadPlatform: {}, // 扩张平台
    "effectNum": 0, //效果线索
    "dayNum": 0, //日处理数据
    "peopleNum": 0, //人群覆盖
    "updateTime": "0000-00-00 00:00:00", //更新时间
    spreadStateData: {
      name:[],
      val: [],
      totalVal: 0,
    }, // 传播度数据
    goodwillStateData: {
      fans: [
        { value: 0, name: "正面" },
        { value: 0, name: "中性" },
        { value: 0, name: "负面" },
      ], // 品牌粉丝情感倾向
      fansName: ["正面", "中性", "负面"],
      media: [
        { value: 0, name: "正面" },
        { value: 0, name: "中性" },
        { value: 0, name: "负面" },
      ], // 网络媒体情感倾向
      mediaName: ["正面", "中性", "负面"],
      emotionMap: [], // 地图数据
    }, // 友好度数据
    fourDimensionData: 0, // 四维综合指数
    interactionWorkData: {
      imageList: [],
      titleList: [],
      valueList: [],
    }, // 作品TOP10数据
    interactionRateData: {
      xAxis: [],
      zan: [],
      comment: [],
      share: [],
      allEound: [],
    }, // 互动度
    mediaTotalData: {}, // 媒体抓取总数据
    platRelatedData: [], // 媒体平台的抓取数据
    channelVolumeData: [], // 渠道分布数据
    mediaData: [], // 处理媒体声量数据
  },
  mutations: {
    // 定时器
    updateTimerObj(state,val) {
      state.timerObj = val;
    },
    // 删除定时器
    deleteTimerObj(state,val) {
      console.log("删除", "11111", state.timerObj)
      clearInterval(state.timerObj);
    },
    // 更新菜单状态
    updateMenuSwiperObj(state,val) {
      state.menuSwiperObj = val;
    },
    // // 更新菜单索引索引
    // updateMenuTabIndex(state,val) {
    //   state.menuTabIndex = val;
    // },
    // 更新面板索引
    updatePanelIndex(state,val) {
      state.panelIndex = val;
    },
    // 更新媒体菜单索引
    updateMediumMenuIndex(state,val) {
      state.mediumMenuIndex = val;
    },
    // 更新品牌数据
    updateBrandsData(state,val) {
      state.brandsData = val;
    },
    // 更新媒体信息
    updateMediaCategory(state,val) {
      state.mediaCategory = val;
    },
    // 更新平台
    updatePlatforms(state,val) {
      state.platforms = val;
    },
    // 更新扩张平台
    updateSpreadPlatform(state,val) {
      state.spreadPlatform = val;
    },
    // 更新效果线索
    updateEffectNum(state,val) {
      state.effectNum = val;
    },
    // 更新效果线索
    updateDayNum(state,val) {
      state.dayNum = val;
    },
    // 更新效果线索
    updatePeopleNum(state,val) {
      state.peopleNum = val;
    },
    // 更新效果线索
    updateUpdateTime(state,val) {
      state.updateTime = val;
    },
    // 更新传播度数据
    updateSpreadStateData(state,val) {
      state.spreadStateData = val;
    },
    // 更新友好度数据
    updateGoodwillStateData(state,val) {
      state.goodwillStateData = val;
    },
    // 更新作品TOP10数据
    updateInteractionWorkData(state,val) {
      state.interactionWorkData = val;
    },  
    // 更新互动度数据
    updateInteractionRateData(state,val) {
      state.interactionRateData = val;
    }, 
    // 更新四维数据
    updateFourDimensionData(state,val) {
      state.fourDimensionData = val;
    },
    // 更新媒体总数据
    updateMediaTotalData(state,val) {
      state.mediaTotalData = val;
    },
    // 更新媒体抓取数据
    updatePlatRelatedData(state,val) {
      state.platRelatedData = val;
    },
    // 更新渠道分布数据
    updateChannelVolumeData(state,val) {
      state.channelVolumeData = val;
    },
    // 更新渠道分布数据
    updateMediaData(state,val) {
      state.mediaData = val;
    },
    
  },
  actions: {
  },
  modules: {
  }
})
