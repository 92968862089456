import service from '@/request/index'; // 请求
import $store from '@/store/index.js';
import * as _ from 'lodash';

// 刷新页面数据
function renovateData(key) {
  // 先清空vuex的数据
  $store.commit('updateEffectNum', 0); // 更新效果线索
  $store.commit('updateDayNum', 0); // 更新日处理数据
  $store.commit('updatePeopleNum', 0); // 更新人群覆盖
  $store.commit('updateUpdateTime', '0000-00-00 00:00:00'); // 更新更新时间
  $store.commit('updateSpreadStateData', {
    name: [],
    val: [],
    totalVal: 0, // 总和
  }); // 处理传播度数据
  $store.commit('updateGoodwillStateData', {
    fans: [
      { value: 0, name: "正面" },
      { value: 0, name: "中性" },
      { value: 0, name: "负面" },
    ],
    fansName: ["正面", "中性", "负面"],
    media: [
      { value: 0, name: "正面" },
      { value: 0, name: "中性" },
      { value: 0, name: "负面" },
    ],
    mediaName: ["正面", "中性", "负面"],
    emotionMap: []
  }); // 处理友好度数据
  $store.commit('updateFourDimensionData', 0); // 处理爱车四维综合指数
  $store.commit('updateInteractionWorkData', {
    imageList: [],
    titleList: [],
    valueList: [],
  }); // 处理互动度
  $store.commit('updateInteractionRateData', {
    xAxis: [],
    zan: [],
    comment: [],
    share: [],
    allEound: [],
  }); // 处理作品TOP10数据
  $store.commit('updateMediaTotalData', {
    followerTotal: 0,
    exposureTotal: 0
  }); // 处理媒体平台总的抓取数据
  $store.commit('updatePlatRelatedData', []); // 处理媒体平台的抓取数据
  $store.commit('updateChannelVolumeData', []); // 处理渠道分布数据
  $store.commit('updateMediaData', []); // 处理媒体声量分布数据

  return new Promise((resolve, reject) => {
    // 请求
    service.get('index', {
      params: {
        brand: key,
        // brand: 'changan'
      }
    }).then(res => {
      if (res.code == 1) {
        let mediaCategory = $store.state.mediaCategory; // 媒体信息
        let platforms = $store.state.platforms; // 扩展平台
        let spreadPlatform = $store.state.spreadPlatform; // 扩展平台
        $store.commit('updateEffectNum', Number.parseFloat(res.data.effect_num) || 0); // 更新效果线索
        $store.commit('updateDayNum', Number.parseFloat(res.data.day_num) || 0); // 更新日处理数据
        $store.commit('updatePeopleNum', Number.parseFloat(res.data.people_num) || 0); // 更新人群覆盖
        $store.commit('updateUpdateTime', res.data.update_time || '0000-00-00 00:00:00'); // 更新更新时间
        try {
          $store.commit('updateSpreadStateData', handleSpreadStateData(spreadPlatform, res.data.brand.spread_rate)); // 处理传播度数据
        } catch (error) {
          console.log(error)
        }
        try {
          $store.commit('updateGoodwillStateData', handleGoodwillState(res.data.brand.friendly_rate)); // 处理友好度数据
        } catch (error) {
          console.log(error)
        }
        try {
          $store.commit('updateFourDimensionData', handleFourDimension(res.data.brand.four_dimension)); // 处理爱车四维综合指数
        } catch (error) {
          console.log(error)
        }
        try {
          $store.commit('updateInteractionWorkData', handleInteractionWork(res.data.brand.interaction_work)); // 处理作品TOP10数据
        } catch (error) {
          console.log(error)
        }
        try {
          // handleInteractionRate(platforms, res.data.brand.interaction_rate, res.data.brand.plat_related)
          $store.commit('updateInteractionRateData', handleInteractionRate(platforms, res.data.brand.interaction_rate, res.data.brand.plat_related)); // 处理互动度数据
        } catch (error) {
          console.log(error)
        }
        try {
          let modifyReturn = handlePlatRelated(platforms, res.data.brand.plat_related, res.data.brand.active_and_loyal);
          $store.commit('updateMediaTotalData', modifyReturn.mediaTotalData); // 处理媒体平台的抓取数据
          $store.commit('updatePlatRelatedData', modifyReturn.platRelatedData); // 处理媒体平台的抓取数据
        } catch (error) {
          console.log(error)
        }
        try {
          $store.commit('updateChannelVolumeData', handleChannelVolume(platforms, res.data.brand.fans_source.channel_volume, res.data.brand.plat_related)); // 处理渠道分布数据
        } catch (error) {
          console.log(error)
        }
        try {
          $store.commit('updateMediaData', handleMedia(mediaCategory, res.data.brand.fans_source.media)); // 处理媒体声量分布数据
        } catch (error) {
          console.log(error)
        }
        resolve()
      } else {
        reject()
      }
    }).catch(err => {
      reject(err)
    })
  })
}

// 处理传播度数据
function handleSpreadStateData(dataHead, data) {
  let decorateData = {
    name: [],
    val: [],
    totalVal: 0, // 总和
  };
  try {
    for (let key in dataHead) {
      decorateData.name.push(dataHead[key]);
      decorateData.val.push(Number.parseFloat(data[key]));
      decorateData.totalVal = _.add(decorateData.totalVal, Number.parseFloat(data[key]));
    }
    decorateData.totalVal = _.multiply(decorateData.totalVal, 0.15);
    // console.log('处理传播度数据', decorateData)
    return decorateData
  } catch (error) {
    return decorateData
  }
}

// 处理友好度数据
function handleGoodwillState(data) {
  try {
    // 品牌粉丝情感倾向
    let fans = [{
      value: data.fans.positive,
      name: `正面:${data.fans.positive}%`, 
    }, {
      value: data.fans.middle,
      name: `中性:${data.fans.middle}%`, 
    }, {
      value: data.fans.negative,
      name: `负面:${data.fans.negative}%`, 
    }];
    let fansName = [
      `正面:${data.fans.positive}%`, 
      `中性:${data.fans.middle}%`,
      `负面:${data.fans.negative}%`, 
    ];
    // 网络媒体情感倾向
    let media = [{
      value: data.media.positive,
      name: `正面:${data.media.positive}%`, 
    }, {
      value: data.media.middle,
      name: `中性:${data.media.middle}%`, 
    }, {
      value: data.media.negative,
      name: `负面:${data.media.negative}%`, 
    }];
    let mediaName = [
      `正面:${data.media.positive}%`, 
      `中性:${data.media.middle}%`, 
      `负面:${data.media.negative}%`,
    ];
    let emotionMap = []; // 地图数据
    for (let key in data.emotion_map) {
      emotionMap.push({
        name: key,
        value: data.emotion_map[key].negative == "" ? 0 : Number.parseFloat(data.emotion_map[key].negative),
        data: [
          data.emotion_map[key].positive == "" ? 0 : Number.parseFloat(data.emotion_map[key].positive), 
          data.emotion_map[key].middle == "" ? 0 : Number.parseFloat(data.emotion_map[key].middle), 
          data.emotion_map[key].negative == "" ? 0 : Number.parseFloat(data.emotion_map[key].negative)
        ]
      })
    }
    return {fans, media, emotionMap, fansName, mediaName}
  } catch (error) {
    return {fans: [
      { value: 0, name: "正面" },
      { value: 0, name: "中性" },
      { value: 0, name: "负面" },
    ], 
    fansName: ["正面", "中性", "负面"],
    media: [
      { value: 0, name: "正面" },
      { value: 0, name: "中性" },
      { value: 0, name: "负面" },
    ], 
    mediaName: ["正面", "中性", "负面"],
    emotionMap: []}
  }
  
}

// 处理四维综合指数
function handleFourDimension(data) {
  let fourVal = 0;
  try {
    for (let key in data) {
      data[key] = data[key] == '' ? 0 : Number.parseFloat(data[key]);
      fourVal = _.add(fourVal, data[key]) 
    }
    fourVal = _.multiply(fourVal, 25);
    return fourVal
  } catch (error) {
    return fourVal
  }
}

// 处理作品TOP10数据
function handleInteractionWork(data) {
  data.sort(function(a,b){
    return b.value - a.value
  })
  let decorateData = {
    imageList: [],
    titleList: [],
    valueList: [],
  };
  try {
    data.forEach((item, index) => {
      decorateData.imageList.push({
        image: item.image,
        keyName: 'a' + index.toString(),
      });
      decorateData.titleList.push(item.title.replaceAll('\n', '').replaceAll('\r', '') + '&_&' + 'a' + index.toString());
      decorateData.valueList.push(item.value);
    })
    return decorateData
  } catch (error) {
    return decorateData
  }
}

// 处理互动数据
function handleInteractionRate(dataHead, data, filterDate) {
  let decorateData = {
    xAxis: [],
    zan: [],
    comment: [],
    share: [],
    allEound: [],
  };
  try {
    for (let key in dataHead) {
      let keyKey = key.split('_')[0];
      if (filterDate[keyKey]) {
        decorateData.xAxis.push(dataHead[key]); // 名字
        decorateData.zan.push(data[key].zan); // 赞美
        decorateData.comment.push(data[key].comment); // 评论数
        decorateData.share.push(data[key].share); // 分享转发
        
        decorateData.allEound.push((Number.parseFloat(data[key].zan) * 0.2) + (Number.parseFloat(data[key].comment) * 0.5) + (Number.parseFloat(data[key].share) * 0.3)); // \综合
      }
    }
    return decorateData
  } catch (error) {
    return decorateData
  }
}


// 处理媒体平台的抓取数据
function handlePlatRelated(dataHead, data, activeAndLoyal) {
  let decorateData = [];
  let followerTotal = 0;
  try {
    for (let dataKey in data) {
      for (let dataHeadKey in dataHead) {
        if (dataHeadKey.split('_')[0] == dataKey) {
          if (decorateData.length <= 6) {
            if (data[dataKey].read_count == 0) {
              // 阅读数为0
              data[dataKey].read_count = _.multiply(_.divide(data[dataKey].follower_count, 30), data[dataKey].article_count)
            }
            followerTotal += data[dataKey].follower_count; // 计算粉丝总数
            decorateData.push({
              nameMedium: dataHead[dataHeadKey],
              key: dataKey,
              keyColour: dataHeadKey,
              ...data[dataKey],
              active: Number.parseFloat(activeAndLoyal[dataHeadKey].active),
              loyal: Number.parseFloat(activeAndLoyal[dataHeadKey].loyal),
            })
          } else {
            break
          }
        }
      }
    }
    // 计算粉丝占比
    decorateData.forEach(item => {
      item['followerScale'] = _.multiply(_.divide(item.follower_count, followerTotal), 100)
    })

    decorateData.sort(function(a,b){
      return b.follower_count - a.follower_count
    })
    return { 
      platRelatedData: decorateData,
      mediaTotalData: {
        followerTotal,
        exposureTotal: followerTotal * 30
      }
    }
  } catch (error) {
    return {
      platRelatedData: [],
      mediaTotalData: {
        followerTotal: 0,
        exposureTotal: 0
      }
    }
  }
}

// 处理渠道分布数据
function handleChannelVolume(dataHead, data, filterData) {
  let decorateData = [];
  try {
    for (let key in dataHead) {
      decorateData.push({
        name: dataHead[key],
        colour: key.split('_')[1],
        key: key.split('_')[0],
        value: Number.parseFloat(data[key]),
      });
    }
    // // 过滤字段
    // let filterDecorateData = [];
    // decorateData.forEach((item) => {
    //   for (let key in filterData) {
    //     if (key == item.key) {
    //       filterDecorateData.push(item)
    //     }
    //   }
    // })
    // return filterDecorateData
    return decorateData
  } catch (error) {
    return decorateData
  }
}

// 处理媒体声量数据
function handleMedia(dataHead, data) {
  let decorateData = [];
  try {
    for (let key in dataHead) {
      decorateData.push({
        name: dataHead[key],
        colour: key.split('_')[1],
        value: Number.parseFloat(data[key])
      });
    }
    return decorateData
  } catch (error) {
    return decorateData
  }
}

export {
  renovateData
}